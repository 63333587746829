import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";
import citySpecificImage from "../images/cities/la_jolla.png";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "Realtors in La Jolla, CA",
      subtitle: "With Your Best Interests At Heart",
      description:
        "This is about more than a deal—it's about your family's happiness and building a great future. So whether you're interested in homes for sale in La Jolla, CA, are looking to sell your house, or would like to explore property investment options, our experienced residential real estate professionals will connect you with the best opportunities. And we'll do it with heart because we're a family too.",
    },
    findNextHome: {
      card1Title: "La Jolla Homes for Sale",
      card2Title: "What Is Your Home Worth?",
    },
    listings: {
      title: "Find Your Place in San Diego",
      description: (
        <p>
          From Allied Gardens to University Heights and every community in between, discover the
          latest San Diego properties here. Our listings are pulled directly from the MLS and
          updated in real-time, giving you immediate access to homes the minute they hit the market.
          Your home search begins here!
        </p>
      ),
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated realtors who help families like yours throughout the homeownership journey—from buying real estate in La Jolla, CA, to selling to investing. We’re advisors who are in your corner from day one.",
    },
    citySpecific: {
      titlePartA: "Why we love",
      titlePartB: "La Jolla",
      p1: "Known for its breathtaking coastline, the hilly, seaside town of La Jolla is an ideal place for nature lovers to call home. Surfers, scuba divers, and snorkelers relish in the warm waters and gentle surf while families enjoy the La Jolla Cove, Birch Aquarium at Scripps, and Windansea Beach. During winter, residents turn to indoor activities, like catching a performance at the La Jolla Playhouse or browsing one of the many museums or art galleries.",
      p2: "It’s no wonder buyers of all kinds are interested in houses for sale in La Jolla! Our Selby team can help you secure your dream property, get the best deal for your current home, or explore the many investment opportunities this city has to offer. No matter what you’re in the market for, when you need La Jolla, CA, real estate assistance, you need The Selby Team.",
      image: citySpecificImage,
    },
    cta: {
      description:
        "Connect with a top-rated team of La Jolla realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };
  return (
    <LayoutWrapper
      title="La Jolla Homes for Sale | Real Estate | Selby Realtors"
      desc="Searching for homes for sale in La Jolla? The Selby Team has the newest listings for this beautiful San Diego-area community."
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
